import React from "react"

import SupportPage from "../components/layout/SupportPage"
import PageHeader from "../components/layout/PageHeader"
import GoogleImage from "../components/GoogleImage"
import TrustpilotSection from "../components/sections/TrustpilotSection"
import ContactUsSection from "../components/sections/ContactUsSection"
import Layout2Columns from "../components/layout/Layout2Columns"


const Page = () => {  
  return (
    <SupportPage page="support" title="Five Star Customer Support | Sign Support">
        <Layout2Columns align="top">
            <div>
              <PageHeader title="Our Customer Support Team" />

              <p>
                Our Goal is to provide 5 star Customer Support. We are happy to help you to determine the Best Solution for 
                your project. Our team of experts will guide you through the process of design, purchasing and installing 
                your sign.
              </p>
              <p>
                If you have purchased our products &amp; have any questions or need support, we are always happy to 
                help.
              </p>

              <ContactUsSection />
            </div>
            <div>
              <GoogleImage src="staff.jpg?v5" required lazy alt="SignMonkey Staff" />
            </div>
        </Layout2Columns>

        <TrustpilotSection />
    </SupportPage>
  )
}

export default Page
