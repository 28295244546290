import React, { useRef } from "react"
import styled from 'styled-components'

const Section = styled.div`    
    padding: 25px;
    background-color: #eee;

    h2 {
        font-size: 25px;
        font-weight: 400;
        margin-top: 0px;
        text-align: center;
        font-family: "Roboto Slab", "Helvetica", "Arial", sans-serif;  
    }
`

function TrustpilotSection(props) {
    const ref = React.useRef(null);

    React.useEffect(() => {
      if (window.Trustpilot) {
          window.Trustpilot.loadFromElement(ref.current, true);
      }
    }, []);

    return (
        <Section>
            <h2>
                Check out our Customer Reviews
            </h2>
            <div
                ref={ref} 
                className="trustpilot-widget"
                data-locale="en-US" 
                data-template-id="53aa8912dec7e10d38f59f36" 
                data-businessunit-id="5a31a4d4b894c90d84225a75" 
                data-style-height="140px" 
                data-style-width="100%" 
                data-theme="light"
                data-stars="1,2,3,4,5"
            >
                <a href="https://www.trustpilot.com/review/signmonkey.com" target="_blank" rel="noopener noreferrer">
                    Trustpilot
                </a> 
            </div>
        </Section>
    );
}

export default TrustpilotSection